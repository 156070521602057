import React, { useEffect, useState } from 'react';
import {
  MenuItem as MenuItemUI,
  withStyles,
  CircularProgress,
  IconButton,
  FormControl,
  Select as SelectUI,
  InputLabel,
  Grid,
  Link,
} from '@material-ui/core';

import { Portlet, PortletContent } from '../../Components';
import Summary from './summary';
import './AddRequestTest.css';
import CloseIcon from '@material-ui/icons/Close';

import { createRequestTest, sendReport } from '../../Services/RequestTestService';
import { getPatientPhoneNumber } from '../../Services/UserService';

import Layout from '../../layout';
import Category from './category';

import styles from './styles';
import SearchPatient from './searchPatient';
import {
  Patient,
  Toast,
  SelectPhysician,
  SearchTest,
} from '../../shared/components';

const AddRequestTest = ({
  prices,
  total,
  tests_selected,
  onTestClick,
  updateTestsSelected,
  removeTesById,
  test_list,
  doctors,
  lab,
  loadPrices,
  onClearClick,
}) => {
  const [doctorID, setDoctorID] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [patient, setPatient] = useState(null);
  const branch_stored = localStorage.getItem('branch');
  const [branch, setBranch] = useState(branch_stored || 1);
  const [errorMessage, setErrorMessage] = useState(null);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedTests, setSelectedTests] = useState([]);

  useEffect(() => {
    loadPrices();
  }, []);

  const updateDoctor = async doctor => {
    if (doctor && doctor.id) {
      setDoctorID(doctor.id);
      setDoctor(doctor);
    } else {
      setDoctorID(null);
      setDoctor(null);
    }
  };

  const cleanInputs = (flag, discount) => {
    if (flag && lab.lab.id === '5abe499eda771218814def3c') {
      sendReport(patient, doctor, test_list, branch, lab.lab.name, total, discount);
    }

    setDoctorID(null);
    setPatient(null);
    setOpen(false);
    setSelectedTests([]);
    onClearClick();
  };

  const saveRequestTest = async (
    tests_selected,
    on_account,
    discount,
    payment_method,
  ) => {
    let go_next = true;

    if (tests_selected.length === 0) {
      go_next = false;
      setErrorMessage('Por Favor selecciona al menos una prueba');
      setOpenError(true);
    }

    if (go_next) {
      try {
        const res = await createRequestTest(
          patient,
          doctorID,
          tests_selected,
          branch,
          discount,
          on_account,
          payment_method,
        );
        setOpenSuccess(true);

        if (lab.lab.sendMessagePatient && lab.lab.sendMessagePatient === true) {
          sendWhatsapp(patient.id, res.data);
        }
      } catch (error) {
        setErrorMessage('Error al crear solicitud de prueba');
        setOpenError(true);
      }

      cleanInputs(false, discount);
    }
  };

  const getTestsText = () => {
    let message = '';
    test_list.map((test, i) => {
      message += `${i + 1}.- ${test.name}\n`;
    });

    return message;
  };

  const sendWhatsapp = async (patient_id, x_request_created) => {
    let message = `Hola *${patient.names} ${patient.last_names}* \n`;
    message += `\n`;
    message += `Lista de Pruebas a realizar:\n`;
    message += getTestsText();
    message += `\n`;

    if (x_request_created.discount > 0) {
      message += `Recibiste un Descuento de: *${x_request_created.discount}Bs*\n`;
    }
    let total_to_pay = x_request_created.total_price;
    if (x_request_created.discount > 0) {
      total_to_pay = total_to_pay - x_request_created.discount;
    }

    // message += `Total a Pagar: *${total_to_pay}Bs*\n`;
    if (x_request_created.on_account > 0) {
      message += `Dejo a cuenta: ${x_request_created.on_account}Bs\n`;
    }

    if (
      x_request_created.on_account > 0 &&
      x_request_created.on_account < total_to_pay
    ) {
      const balance = total_to_pay - x_request_created.on_account;
      message += `Tienes un Saldo de: *${balance}Bs*\n`;
    }

    message += `Para revisar el estado de las pruebas, puedes hacerlo en el siguiente enlace: *https://www.laztin.com/status/${x_request_created.shortid}*\n`;

    message += `\n`;
    message += `*Laboratorio ${lab.lab.name}*\n`;

    const phoneNumber = await getPatientPhoneNumber(patient_id);

    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
        message,
      )}`,
      '_blank',
    );
  };

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setBranch(value);
    localStorage.setItem('branch', value);
  };

  const handleDelete = ev => {
    ev.preventDefault();
    setPatient(null);
  };

  const handleOpen = ev => {
    ev.preventDefault();
    setOpen(true);
  };

  let show_search = null;

  if (open) {
    show_search =
      patient == null ? (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SearchPatient
            setPatient={setPatient}
            patient={patient}
            setOpen={setOpen}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Patient {...patient} />
            <IconButton onClick={handleDelete}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </>
      );
  }

  return (
    <Layout>
      <Portlet>
        <PortletContent>
          <Grid container spacing={3}>
            {open ? (
              show_search
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Link component="button" variant="body2" onClick={handleOpen}>
                  Crear o Buscar Paciente
                </Link>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SelectPhysician
                doctors={doctors}
                doctorID={doctorID}
                updateDoctor={updateDoctor}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {lab.lab && lab.lab.branchs && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="branch">Sucursal</InputLabel>
                  <SelectUI
                    labelId="branch"
                    id="branch-select"
                    value={branch}
                    label="Sucursal"
                    name="branch"
                    onChange={handleBranch}
                    defaultValue={branch}
                  >
                    {lab.lab.branchs.map(branch => {
                      return (
                        <MenuItemUI key={branch.id} value={branch.id}>
                          {branch.name}
                        </MenuItemUI>
                      );
                    })}
                  </SelectUI>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SearchTest
                categories={prices}
                selectedTests={selectedTests}
                setSelectedTests={setSelectedTests}
                updateTestsSelected={updateTestsSelected}
                removeTesById={removeTesById}
              />
            </Grid>
          </Grid>
          <div className="grid-x">
            <div className="medium-12">
              <div className="medium-12 cell">
                <div className="columns_x">
                  {prices ? (
                    <Category prices={prices} onTestClick={onTestClick} />
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
              <Summary
                test_list={test_list}
                total={total}
                cleanInputs={cleanInputs}
                saveRequestTest={saveRequestTest}
                tests_selected={tests_selected}
              />
            </div>
          </div>
          <Toast
            statusOpen={openSuccess}
            setOpen={setOpenSuccess}
            type="success"
            message="Se creado exitosamente la solicitud"
          />
          <Toast
            statusOpen={openError}
            setOpen={setOpenError}
            type="error"
            message={errorMessage}
          />
        </PortletContent>
      </Portlet>
    </Layout>
  );
};
export default withStyles(styles)(AddRequestTest);
